import { User } from "@/interfaces/firebase";
import { useEffect, useState } from "react";
import Skeleton from "../Skeleton";
import IconArrowRight from "../IconArrowRight";
import { db } from "@/connectors/firebase";
import { doc, getDoc } from "firebase/firestore";
import { useRouter } from "next/navigation";
import Avatar from "../Avatar";

interface messageCardGroupProps {
    title: string,
    participants?: string[],
    theme?: keyof typeof themes,
    subtitle: string,
    onClick?: () => void,
}

const themes = {
    meeting: {
        bg: "bg-primary-3.1",
        title: "text-white",
        arrow: "#FFFFFF"
    },
    joinedMeeting: {
        bg: "bg-grey-1.1 border-b-[4px] border-b-primary-3.2",
        title: "text-primary-3.2",
        arrow: "#004ECB"
    }
}

export default function MessageCardGroup({ title, participants, theme = "meeting", subtitle, onClick }: messageCardGroupProps) {
    const route = useRouter();
    const [toUsers, setToUsers] = useState<User[]>([]);
    const [imagesLoaded, setImagesLoaded] = useState<boolean[]>([]);

    const goToMeetingRecord = (id?: string) => {
        if (onClick) {
            onClick()
            return
        }
        if (!id) return;
        route.push(`/meeting/meeting-record?id=${id}`);
    }

    useEffect(() => {
        loadUsers()
    }, [])

    const loadUsers = async () => {
        if (!participants) return
        for (const participant of participants) {
            const toRef = doc(db, "users", participant);
            const toSnap = await getDoc(toRef);
            const toUser = toSnap.data() as User;
            setToUsers((prev) => [...prev, toUser]);
            setImagesLoaded((prev) => [...prev, false])
        }
    }

    return <div className={`w-full h-[105px] ${themes[theme].bg} shadow-drop-shadow flex justify-between items-center flex-row px-[30px]`} onClick={() => goToMeetingRecord()}>
        <div className="flex flex-wrap w-2/5 h-full items-center relative">
            {
                toUsers.map((user, key) => {
                    if (key > 5) return;
                    if (!user.avatarUrl) imagesLoaded[key] = true;
                    return <div className="w-[33.22px] h-[33.22px] absolute" style={{ left: 20 * key }} key={user.id + key}>
                        {
                            user.avatarUrl ?
                                <Avatar url={user.avatarUrl} size="!w-[33.22px] h-[33.22px] !border-primary-4 !border-[1.5px]"/>
                                :
                                <div className="w-[33.22px] h-[33.22px] rounded-full border-primary-4 border-[1.5px] object-cover bg-white flex items-center justify-center">
                                    <p className="text-primary-3 font-bold text-sm">{`${user?.firstName?.substring(0, 1).toUpperCase()}`}</p>
                                </div>
                        }
                    </div>
                })
            }
            {
                toUsers.length > 5 ?
                    <div className="w-[33.22px] h-[33.22px] absolute" style={{ left: 120 }}>
                        <div className="w-[33.22px] h-[33.22px] rounded-full border-primary-4 border-[1.5px] object-cover bg-white flex items-center justify-center">
                            <p className="text-primary-3 font-bold text-sm">+{toUsers.length - 5}</p>
                        </div>
                    </div>
                    : null
            }
        </div>
        <div className="flex flex-row justify-end w-3/5 space-x-6">
            <div className="flex flex-col">
                <p className="text-grey-3 font-bold text-xs leading-[13.8px] text-end">{subtitle}</p>
                <p className={`${themes[theme].title} font-bold text-19px leading-[21.85px] text-end`}>{title || "title is not exist"}</p>
            </div>
            <div className="flex items-center justify-end">
                <IconArrowRight color={themes[theme].arrow} width="2" className="h-3" />
            </div>
        </div>
    </div>
}