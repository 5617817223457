import Image from "next/image"
import IconTalk from "../IconTalk"
import ArrowUp from "../../../public/ArrowUp2.svg"
import { ChangeEvent, FocusEvent, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { AnimatePresence, motion } from "framer-motion";
import useAuth from "@/hooks/useAuth";
import { Timestamp } from "firebase/firestore";
import { Action, Conversation, IMessage } from "@/interfaces/firebase";
import { auth } from "@/connectors/firebase";
import MessageAssistant from "../MessageAssistant";
import { useScroll } from "@/hooks/scroll";
import DynamicComponent from "../DynamicComponent";
import { sendMessageToAI } from "@/services/ai";
import { sendMessageToAPI } from "@/services/api";
import { useNotifications } from "@/contexts/notifyContext";
import { MdHeadset } from "react-icons/md";
import { chatsProps, useMessages } from "@/contexts/messagesContext";
import { useRouter } from "next/navigation";
import PreLoadingComponents from "../PreLoadingComponents";
import { actionAI } from "@/functions/actionChecker";
import LoadingAiResponse from "../LoadingAIResponse";

export interface MessageInputProps {
	placeholder: string,
	context?: string,
	textDefault?: string,
	addMessages?: IMessage[],
	type?: keyof typeof types,
	disableButtons?: boolean,
	hideSendButton?: boolean,
	alignVerticalMicrophone?: "items-start" | "items-center" | "items-end",
	className?: string,
	fillText?: string,
	setFocus?: boolean,
	setShowHandsFree?: boolean,
	sendTextToContext?: string,
	onResetAddMessages?: () => void,
	onHandsFree?: () => void,
	onSendMessage?: (text: string) => void,
	onInput?: (text: string) => void,
	onFocus?: (focused: boolean) => void,
	onRequestRefresh?: () => void,
}

const types = {
	AI: {
		iconColor: 'text-primary-2',
		borderColor: 'border-l-primary-3',
		background: 'bg-primary-3'
	},
	User: {
		iconColor: 'text-primary-4',
		borderColor: 'border-l-primary-4',
		background: 'bg-primary-4'
	},
}

const sendMessageButtonAnimation = {
	show: { x: 0, opacity: 1, width: "40px", marginLeft: "4px", transition: { type: 'spring', duration: 0.4, width: { duration: 0.3 } } },
	hide: { x: -100, opacity: 0, width: "0px", marginLeft: "0px", transition: { type: 'spring', duration: 0.4, width: { duration: 0.3 } } },
}

const isIOS = () => {
	return /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
}

export const checkMicrophonePermission = async () => {
	const microphoneTutorialAndroid = `
        How to Grant Microphone Permissions:

        If you previously denied permissions for the microphone, you can enable them again by following these steps:

        For Android Devices:
        1. Open Settings.
        2. Navigate to Apps.
        3. Find Your App Browser.
        4. Open Permissions.
        5. Enable Microphone Permission.
        6. Confirm Changes.

        If you still cannot use the microphone after granting permission, check if any security apps are blocking access. Restarting your device may also help.
    `;
	const microphoneTutorialIOS = `
        How to Grant Microphone Permissions:

        If you previously denied permissions for the microphone, you can enable them again by following these steps:

        For iOS Devices:
        1. Open Settings.
        2. Scroll to Safari App.
        3. Tap on the Safari App.
        5. Scroll to find permissions.
        6. Adjust Permissions (allow).
        7. Confirm Changes.

        If you still cannot use the microphone after granting permission, check if any security apps are blocking access. Restarting your device may also help.
    `;
	// Check if the browser supports navigator.permissions.query
	if (navigator.permissions) {
		try {
			const permissionStatus = await navigator.permissions.query({ name: 'microphone' as PermissionName });

			if (permissionStatus.state === 'granted') {
				console.log("Microphone permission already granted.");
				return true;
			} else if (permissionStatus.state === 'denied') {
				console.log("Microphone permission denied.");
				if (isIOS()) {
					alert(microphoneTutorialIOS);
				}
				else {
					alert(microphoneTutorialAndroid);
				}
				return false;
			} else {
				// Request permission for the microphone
				try {
					const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
					stream.getTracks().forEach(track => track.stop()); // Stop audio capture after requesting permission
					console.log("Microphone permission granted.");
					return true;
				} catch (err) {
					console.error("Error requesting microphone permission:", err);
					if (isIOS()) {
						alert(microphoneTutorialIOS);
					}
					else {
						alert(microphoneTutorialAndroid);
					}
					return false;
				}
			}
		} catch (err) {
			console.error("Error checking microphone permission:", err);
			return false;
		}
	} else {
		// If the browser does not support navigator.permissions.query, request directly
		try {
			const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
			stream.getTracks().forEach(track => track.stop()); // Stop audio capture after requesting permission
			console.log("Microphone permission granted.");
			return true;
		} catch (err) {
			console.error("Error requesting microphone permission:", err);
			if (isIOS()) {
				alert(microphoneTutorialIOS);
			}
			else {
				alert(microphoneTutorialAndroid);
			}
			return false;
		}
	}
};

export default function MessageInput({ placeholder, context = "", textDefault = "", addMessages = [], type = "AI", fillText = "", setShowHandsFree = false, sendTextToContext = "", alignVerticalMicrophone = "items-end", disableButtons = false, hideSendButton = false, setFocus = false, className, onHandsFree, onResetAddMessages, onSendMessage, onFocus, onInput, onRequestRefresh, ...props }: MessageInputProps) {
	const [text, setText] = useState(textDefault)
	const [recording, setIsRecording] = useState(false)
	const [disable, setDisable] = useState(false)
	const [sendingMessage, setSendingMessage] = useState(false)
	const [enableMessages, setEnableMessages] = useState(false)
	const refTextArea = useRef<HTMLTextAreaElement>(null)
	const containerRef = useRef<HTMLDivElement>(null)
	const scrollRef = useRef<HTMLDivElement>(null)
	const waitingAiEl = useRef<HTMLDivElement>(null)
	const recognitionRef = useRef<SpeechRecognition | undefined>(undefined)
	const { currentUser } = useAuth()
	const scroll = useScroll()
	const [loadMessages, setLoadMessages] = useState(false)
	const [conversation, setConversation] = useState<Conversation | null>(null)
	const [allMessages, setAllMessages] = useState<IMessage[]>([])
	const [allMessagesBkp, setAllMessagesBkp] = useState<IMessage[]>([])
	const [loading, setLoading] = useState(true)
	const [fileExplorerOpen, setFileExplorerOpen] = useState(false);
	const [isFileExplorerOpen, setIsFileExplorerOpen] = useState(false);
	const [addedMessage, setAddedMessage] = useState("");
	const [onFocusInputText, setOnFocusInputText] = useState<boolean>(false)
	const { addNotification } = useNotifications()
	const { messages, isLoadingMessages } = useMessages()
	const router = useRouter()

	useEffect(() => {
		setDisable(disableButtons)
	}, [disableButtons])

	const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
		setText(event.target.value);
		onInput?.(event.target.value)
		if (event.target.value) {
			event.target.style.height = `${Math.min(Math.max(event.target.scrollHeight - 19, 30), 60)}px`;
			event.target.style.lineHeight = `normal`
		}
		else {
			event.target.style.height = `30px`
			event.target.style.lineHeight = `30px`
		}
	};

	const sendMessage = async () => {
		if (disable) return
		if (onSendMessage && !context) onSendMessage(text)
		if (context) sendMessageToContext(text)
		setText('')
		refTextArea.current!.style.height = `30px`
		refTextArea.current!.style.lineHeight = `30px`
	}

	const handleOnRecord = async () => {
		if (disable) return
		setIsRecording(true);
		const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
		const hasMicrophonePermission = await checkMicrophonePermission();
		if (!hasMicrophonePermission) return;
		if (!SpeechRecognition) {
			alert("Speech Recognition API is not supported by your browser.");
			console.log("Speech Recognition API is not supported by your browser.");
			return;
		}
		recognitionRef.current = new SpeechRecognition();
		recognitionRef.current.continuous = true;
		recognitionRef.current.interimResults = true;

		recognitionRef.current.onend = async (e) => {
			console.log(e)
			console.log("end recognition")
		}
		recognitionRef.current.onresult = async (e) => {
			console.log(e)
			const transcript = e.results[0][0].transcript;
			setText(transcript);
			refTextArea.current!.style.height = `${Math.min(Math.max(refTextArea.current!.scrollHeight - 19, 30), 60)}px`;
			refTextArea.current!.style.lineHeight = 'normal';
			console.log("transcript recognition")
		};
		recognitionRef.current.start();
	}

	const handleLeaveRecord = () => {
		setIsRecording(false);
		if (recognitionRef.current) {
			recognitionRef.current.stop();
		}
	}

	const handleOnFocus = () => {
		if (!context) return
		if (!allMessages.length) return
		setEnableMessages(true)
		if (onFocus) onFocus(true)
	}

	const handleOnFocusInputText = () => {
		setOnFocusInputText(true)
	}

	const handleBlur = (e: FocusEvent<HTMLDivElement>) => {
		if (setFocus) return
		if (!context) return
		if (containerRef.current && containerRef.current.contains(e.relatedTarget)) {
			return;
		}
		if (!fileExplorerOpen || (fileExplorerOpen && !isFileExplorerOpen)) {
			setEnableMessages(false)
			if (onFocus) onFocus(false)
		}
	}

	const sendMessageToContext = useCallback(async (text: string) => {
		try {
			if (!text) return;
			if (!conversation || sendingMessage) return;

			setSendingMessage(true)

			const newMessage: IMessage = {
				conversation: conversation!.id,
				timestamp: Timestamp.now(),
				from: currentUser.id, // ID of the user sending the message
				data: {
					content: text,
					role: 'user',
				}
			}

			setAllMessages((prev) => [...prev, newMessage])
			setAllMessagesBkp((prev) => [...prev, newMessage])
			setConversation((prev) => {
				if (prev) return { ...prev, awaitingAIMessage: true }
				return null
			})

			const token = await auth.currentUser?.getIdToken(true) ?? ""

			await sendMessageToAPI({
				token: token,
				route: "chat/create",
				data: {
					id: currentUser.id,
					conversationId: conversation!.id,
					messageCount: allMessages.length,
					lastInteraction: Timestamp.now(),
					awaitingAIMessage: true,
				}
			})

			if (allMessages.length == 0 || allMessages.length == 1) {
				setEnableMessages(true)
				if (onFocus) onFocus(true)
			}

			await sendMessageToAPI({ token: token, route: "chat/send-message", data: { ...newMessage, id: currentUser.id } })

			const id = auth.currentUser?.uid ?? ""

			await sendMessageToAI({ token: token, id: id, conversationId: conversation.id })
			setSendingMessage(false)
		} catch (error: any) {
			setSendingMessage(false)
			addNotification({ title: "Error", body: error, type: "error" })
		}
	}, [currentUser, conversation, allMessages]);

	useEffect(() => {
		if (isLoadingMessages) return
		if (currentUser.id && context) {
			foundChat(messages)
		}
	}, [messages, isLoadingMessages])

	const checkIsSuggestion = (content: string): boolean => {
		try {
			let convertContent = JSON.parse(content)
			if (convertContent?.type === "showSuggestionsButtons") return true
			return false
		} catch (error) {
			return false
		}
	}

	const foundChat = async (messages: chatsProps) => {
		// if (!Object.values(messages).length) return
		let foundChat = Object.values(messages).filter(f => f.context === context && f.participants?.includes(currentUser.id) && f.participants.includes("RAI"))
		if (!foundChat.length) {
			const token = (await auth.currentUser?.getIdToken(true)) ?? "";
			await sendMessageToAPI({
				token: token,
				route: "chat/create",
				data: {
					id: currentUser.id,
					participants: ["RAI", currentUser.id],
					context: context,
					awaitingAIMessage: false,
				},
			});
			return
		}
		let conversationDoc = foundChat[0];
		setConversation(conversationDoc);
		// MESSAGES
		let allMessage: IMessage[] = conversationDoc.messages || []
		const removedAllSuggestions = allMessages.filter(f => f.data.role === "assistant" && f.data.content && checkIsSuggestion(f.data.content))
		if (allMessage.length < allMessagesBkp.length) return
		if (allMessagesBkp.length != allMessage.length) {
			setAllMessages(allMessage.sort((a, b) => a.timestamp.seconds - b.timestamp.seconds))
			setAllMessagesBkp(allMessage.sort((a, b) => a.timestamp.seconds - b.timestamp.seconds))
			setTimeout(() => {
				// SUGGESTIONS FOR Needs & leads pages
				setAddedMessage("")
			}, 1000);
		}
		// if (removedAllSuggestions.length !== allMessages.length) {
		// 	// SUGGESTIONS FOR Needs & leads pages
		// }
		setLoading(false)
		setTimeout(() => {
			setLoadMessages(true)
		}, 300)
		if (setFocus) setEnableMessages(true)
		let lastMessage = allMessage[allMessage.length - 1]
		if (allMessage.length && lastMessage && lastMessage.data.role === "assistant" && lastMessage.data.content) {
			if (onRequestRefresh) onRequestRefresh()
		}
	}

	useEffect(() => {
		// first loading chat AI
		if (!loading && context) {
			if (scrollRef.current && conversation?.id && scroll.positions[conversation.id]) {
				scrollRef.current.scrollTo({ top: scroll.positions[conversation.id], behavior: "instant" })
				scrollRef.current.style.visibility = "visible"
			} else {
				if (scrollRef.current) scrollRef.current.style.visibility = "visible"
				setTimeout(() => {
					waitingAiEl?.current?.scrollIntoView({ behavior: "smooth", block: "center", inline: "end" })
				}, 500)
			}
		}
	}, [enableMessages])

	const isWaitingAI = useMemo(() => {
		if (conversation) {
			return conversation.awaitingAIMessage
		} else {
			return false
		}
	}, [conversation])

	useEffect(() => {
		setDisable(isWaitingAI)
	}, [isWaitingAI])

	useEffect(() => {
		if (isWaitingAI) {
			// AI response scroll down
			setTimeout(() => {
				waitingAiEl?.current?.scrollIntoView({ behavior: "smooth", block: "center", inline: "end" })
			}, 200)
			// If AI doesn't respond within 5 minutes remove the loading response
			setTimeout(async () => {
				if (isWaitingAI && conversation && conversation.awaitingAIMessage) {
					try {
						const token = await auth.currentUser?.getIdToken(true) ?? ""
						await sendMessageToAPI({
							token: token,
							route: "chat/create",
							data: {
								id: currentUser.id,
								conversationId: conversation.id,
								messageCount: allMessages.length,
								lastInteraction: Timestamp.now(),
								awaitingAIMessage: false,
							}
						})
					} catch (error: any) {
						addNotification({ title: "Error", body: error, type: "error" })
					}
				}
			}, 300000);
		}
	}, [isWaitingAI])

	useEffect(() => {
		if (loadMessages) {
			setTimeout(() => {
				waitingAiEl?.current?.scrollIntoView({ behavior: "smooth", block: "center", inline: "end" })
			}, 200)
		}
	}, [allMessages])

	useEffect(() => {
		// SUGGESTIONS FOR Needs & leads pages
		if (!addMessages.length || addedMessage === JSON.stringify(addMessages)) return;
		console.log("Explore useEffect 1: ", addMessages)
		const updatedMessages = addMessages.map((message) => ({
			...message,
			timestamp: Timestamp.now(),
		}));
		console.log("Explore useEffect 2 ")
		const updatedMessagesString = JSON.stringify(updatedMessages);
		if (addedMessage === updatedMessagesString) return;

		setAddedMessage(updatedMessagesString);
		setTimeout(() => {
			console.log("Explore useEffect 3 ")
			setAllMessages([...allMessagesBkp, ...updatedMessages]);
		}, 3000);
	}, [allMessagesBkp, addMessages]);

	useEffect(() => {
		if (sendTextToContext) {
			sendMessageToContext(sendTextToContext)
			handleOnFocus()
		}
	}, [sendTextToContext])

	useEffect(() => {
		if (fillText) {
			setText(fillText)
			if (refTextArea.current) {
				refTextArea.current.style.height = `70px`;
				refTextArea.current.style.lineHeight = `normal`
			}
		}
	}, [fillText])

	const handleScroll = () => {
		if (scrollRef.current && conversation?.id) {
			const { scrollTop, scrollLeft } = scrollRef.current
			scroll.set(conversation.id, scrollTop)
		}
	}

	const sendMessageSuggestion = (text: string, message: IMessage) => {
		// SUGGESTIONS FOR Needs & leads pages
		setAllMessages(allMessagesBkp)
		onResetAddMessages?.()
		if (message.data.role === "assistant" && message.data.content) {
			let content: Action = JSON.parse(message.data.content)
			if (content.type == "showSuggestionsButtons") {
				let selectedOption = content.buttons.find(f => f.text == text);
				if (selectedOption && selectedOption.path) {
					router.push(selectedOption.path)
				}
				if (selectedOption && selectedOption.index) {
					if (context) {
						sendMessageToContext(text)
					}
					else {
						onSendMessage?.(text)
					}
				}
				if (selectedOption && !selectedOption.index && !selectedOption.path) {
					setEnableMessages(false)
					if (onFocus) onFocus(false)
				}
			}
		}
	}

	const isResponsing = useMemo((): { messages: string[], action: string } => {
		try {
			const lastMessage = allMessages.slice(-1).pop()
			if (!lastMessage) return { messages: [], action: "" }
			if (lastMessage.from != "RAI") return { messages: [], action: "" }
			if (lastMessage.data.role !== "assistant" || lastMessage.data.content) return { messages: [], action: "" }
			if (!lastMessage.data.tool_calls?.length) return { messages: [], action: "" }
			const actionName = lastMessage.data.tool_calls[0].function.name || ""
			const { message } = actionAI[actionName]
			if (message) return { messages: message, action: actionName }
			return { messages: [], action: "" }
		} catch (error) {
			console.log(error)
			return { messages: [], action: "" }
		}
	}, [allMessages])

	const handleClickOutside = (event: MouseEvent) => {
		if (
			refTextArea.current &&
			!refTextArea.current.contains(event.target as Node)
			&&
			!text
		) {
			setOnFocusInputText(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [onFocusInputText, text]);

	return (
		<div ref={containerRef} onFocus={handleOnFocus} onBlur={handleBlur} tabIndex={0}>
			<AnimatePresence>
				{
					enableMessages &&
					<motion.div
						initial={{ height: 0 }}
						animate={{ height: 'auto', transition: { type: "spring", duration: 0.3 } }}
						exit={{ opacity: 0, height: 0, transition: { duration: 0.3 } }}
						className="messages">
						<div ref={scrollRef} className="snap-y overflow-y-auto overflow-x-hidden bg-white px-2 pt-2 mb-2 space-y-6 flex-1 flex flex-col pb-1 max-h-[400px] invisible scrollbar-thin" onScroll={handleScroll}>
							{
								allMessages.sort((a, b) => a.timestamp.seconds - b.timestamp.seconds)
									.map((message, key) => {
										return <DynamicComponent
											key={key + message.id!}
											message={message}
											messagesLength={allMessages.length}
											index={key + 1}
											setFileExplorerOpen={setFileExplorerOpen}
											onSendMessage={(text) => sendMessageSuggestion(text, message)}
											onBlur={setIsFileExplorerOpen} />
									})
							}
							<div ref={waitingAiEl}>
								{
									isWaitingAI &&
									<div className="space-y-3 flex flex-col">
										{
											isResponsing.action ?
												<PreLoadingComponents {...isResponsing} />
												:
												<MessageAssistant message={<LoadingAiResponse />} />
										}
									</div>
								}
							</div>
						</div>
					</motion.div>
				}
			</AnimatePresence>
			<div
				className={`${className ?? ''} w-full flex flex-row space-x-2 items-end`}
			>
				<motion.div
					layout
					className={`rounded-sm min-w-[280px] w-full border border-grey-1 flex flex-row z-[1]`}
				>
					<div className={`w-full rounded-sm flex justify-between ${alignVerticalMicrophone} py-[5px] pr-[9px] border-l-[5px] ${disable ? "border-l-grey-2.2" : types[type].borderColor} bg-white`}>
						<textarea
							ref={refTextArea}
							readOnly={disable}
							value={text}
							onFocus={handleOnFocusInputText}
							onKeyDown={(e) => {
								if (e.key == "Enter" && !text) {
									e.preventDefault()
									e.stopPropagation()
								}
								if (e.key == "Enter" && !e.shiftKey) {
									sendMessage()
								}
							}}
							onChange={(e) => handleChange(e)}
							placeholder={placeholder}
							className="w-[calc(100%_-_30px_-_1rem)] pt-[5px] h-[30px] leading-[30px] align-middle justify-center flex text-[16px] placeholder:text-sm scroll-auto text-gray-600 placeholder:font-normal outline-none px-2 mx-1 scrollbar-thin overflow-y-auto resize-none font-light touch-manipulation"
						/>
						<div className="w-[30px] h-[30px] cursor-pointer flex justify-center items-center bg-grey-1.1 rounded-sm" onPointerDown={handleOnRecord} onMouseLeave={handleLeaveRecord} onPointerUp={handleLeaveRecord}>
							<IconTalk className={`w-[10px] ${disable ? "text-grey-2.2" : types[type].iconColor} ${recording && !disable ? "recording" : ""}`} />
						</div>
					</div>
				</motion.div>
				<div className={`${setShowHandsFree ? "" : "hidden"} w-[40px] h-[40px] cursor-pointer flex justify-center items-center ${disable ? "bg-grey-2.2" : types[type].background} rounded-sm`} onClick={onHandsFree}>
					<MdHeadset className="w-3 h-3 text-white" />
				</div>
				<AnimatePresence>
					{
						!hideSendButton ?
							<motion.div
								layout
								initial="hide"
								animate={onFocusInputText ? "show" : "hide"}
								exit="hide"
								variants={sendMessageButtonAnimation}
								className="flex"
							>
								<div className={`w-[40px] h-[40px] cursor-pointer flex justify-center items-center ${disable ? "bg-grey-2.2" : types[type].background} rounded-sm`} onClick={sendMessage}>
									<Image src={ArrowUp} alt="sendMessage" className="w-3 h-3" />
								</div>
							</motion.div>
							: null
					}
				</AnimatePresence>
			</div>
		</div>
	)
}