import useAuth from "@/hooks/useAuth";
import MessageAssistant from "../MessageAssistant";
import ProfilePreview from "../ProfilePreview";
import MessageButton from "../MessageButton";
import { useMemo, useState } from "react";

interface FirstCardOnboardingProps {
  accepted: boolean;
  onClickMessage: (decision: boolean) => void;
  loading: boolean;
}

export default function FirstCardOnboarding({
  accepted,
  onClickMessage,
  loading,
}: FirstCardOnboardingProps) {
  const { currentUser } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const profileError = useMemo(() => {
    return currentUser.onboardingStatus == `error`;
  }, [currentUser]);

  const profileAccepted = useMemo(() => {
    return (
      currentUser.onboardingStatus == `profileAccepted` ||
      currentUser.onboardingStatus == `complete`
    );
  }, [currentUser]);

  const profilePending = useMemo(() => {
    return currentUser.onboardingStatus == `pending`;
  }, [currentUser]);

  return (
    <div className="flex flex-col justify-between space-y-6 flex-1">
      <div className="flex flex-col space-y-3 flex-1">
        <MessageAssistant
          message={
            <>
              Welcome to GBS!<br /><br />
              We want to make making the right connections easier, so we’ve built our event app around predictive artificial intelligence.<br /><br />
              AI is great, <span className="font-extrabold">but not perfect.</span> It still needs the human touch to provide direction.<br /><br />
              <span className="font-extrabold">We understand that your privacy is paramount, so nothing is published until you confirm.</span><br /><br />
              We’ve used publicly available information to start building an event profile for you <span className="font-extrabold">in private.</span><br /><br />
              It may not be the most up-to-date information. Let’s make sure it’s what you want together...


            </>
          }
          loading={loading}
        />
        <ProfilePreview
          firstName={currentUser.firstName}
          lastName={currentUser.lastName}
          image={currentUser.avatarUrl}
          className="mt-[22px]"
          loading={loading}
        />
      </div>

      <div className="space-y-3">
        {(profilePending || (profileAccepted && accepted)) && (
          <MessageButton
            text="Let’s edit together."
            onClick={() => {
              if (isLoading || profileAccepted) return
              setIsLoading(true)
              if (profilePending) onClickMessage(true);
            }}
            loading={isLoading}
          />
        )}

        {/* {(profilePending || (profileError && !accepted)) && (
          <MessageButton
            text="That's not me."
            theme="light"
            onClick={() => {
              if (profilePending) {
                onClickMessage(false);
              }
            }}
            loading={loading}
          />
        )} */}
      </div>
      {
        <div>
          {profileError && (
            <div className="py-8">
              <MessageAssistant
                message={
                  <>{`Oh, sorry to hear that. You will be contacted by a human soon.`}</>
                }
              />
            </div>
          )}
        </div>
      }
    </div>
  );
}
