import { Action, IMessage, User } from "@/interfaces/firebase";
import Message from "../Message";
import MessageAssistant from "../MessageAssistant";
import Markdown from "react-markdown";
import Profile from "../Profile";
import ContactSuggestion from "../ContactSuggestion";
import EventCard from "../EventCard";
import MessageButton from "../MessageButton";
import MessageCard from "../MessageCard";
import IconSchedule from "../IconSchedule";
import { useRouter } from "next/navigation";
import { Timestamp } from "firebase/firestore";
import FirstCardOnboarding from "../FirstCardOnboarding";
import SecondCardOnboarding from "../SecondCardOnboarding";
import ThirdCardOnboarding from "../ThirdCardOnboarding";
import React, { useCallback, useState } from "react";
import { auth, storage } from "@/connectors/firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import useAuth from "@/hooks/useAuth";
import { sendMessageToAPI } from "@/services/api";
import DealCard from "../DealCard";
import { useNotifications } from "@/contexts/notifyContext";
import OfferMeeting from "../OfferMeeting";
import ConfirmMeeting from "../ConfirmMeeting";
import CancelMeeting from "../CancelMeeting";
import MessageCardGroup from "../MessageCardGroup";
import SuggestionList from "../SuggestionList";
import SuggestionButton from "../SuggestionButton";
import RequestDeal from "../RequestDeal";
import RequestedDeal from "../RequestedDeal";
import ChooseImage from "../ChooseImage";
import { actionAI } from "@/functions/actionChecker";
import { formatFirestoreTimestamp, getEventDay } from "@/functions/format";
import TryAgainAI from "../TryAgainAI";
import dayjs from "dayjs";

interface DynamicComponentProps {
  message: IMessage;
  index: number;
  toUser?: User;
  loading?: boolean;
  premiumMember?: boolean;
  messagesLength: number;
  onboardedViaAI?: boolean;
  acceptProfile?: (decision: boolean, message: IMessage) => void;
  completeOnboarding?: (message: IMessage) => void;
  setFileExplorerOpen?: (isOpen: boolean) => void;
  onSendMessage?: (text: string) => void;
  onBlur?: (blur: boolean) => void;
}

const ActionComponent = React.memo(
  ({
    message,
    action,
    loading,
    onboardedViaAI,
    acceptProfile,
    completeOnboarding,
    setFileExplorerOpen,
    onBlur,
    onSendMessage,
    index,
    messagesLength,
  }: DynamicComponentProps & { action: Action; loading?: boolean }) => {
    const route = useRouter();
    const { currentUser } = useAuth();
    const [avatarLoading, setAvatarLoading] = useState(false);
    const [loadingTryAgainProcessAI, setLoadingTryAgainProcessAI] = useState(false);
    const { addNotification } = useNotifications();

    const goToProfile = useCallback(
      (id: string) => {
        if (!id) return;
        if (currentUser.onboardingStatus !== "complete") return
        route.push(`/profile?id=${id}`);
      },
      [route]
    );

    const goToEvent = useCallback(
      (id: string) => {
        if (!id) return;
        route.push(`/event?id=${id}`);
      },
      [route]
    );

    const goToChat = useCallback(
      (id: string) => {
        if (!id) return;
        route.push(`/chat?id=${id}`);
      },
      [route]
    );

    const goToMeeting = useCallback(
      (date: Timestamp, id: string) => {
        if (!id) return;
        if (date.seconds > Timestamp.now().seconds) {
          route.push(`meetings/meeting-record?id=${id}`);
        } else {
          route.push(`meetings/meeting?id=${id}`);
        }
      },
      [route]
    );

    const goToDeal = useCallback(
      (id: string) => {
        if (!id) return;
        route.push(`/deals/deal?id=${id}`);
      },
      [route]
    );

    const goToConferenceAgenda = () => {
      route.push("/schedule");
    };

    const chooseAvatar = useCallback(
      async (file: File, message: IMessage) => {
        if (avatarLoading) return
        setAvatarLoading(true);
        try {
          const token = (await auth.currentUser?.getIdToken(true)) ?? "";
          await sendMessageToAPI({
            token: token,
            route: "chat/create",
            data: {
              id: currentUser.id,
              conversationId: message.conversation,
              messageCount: messagesLength,
              lastInteraction: Timestamp.now(),
              awaitingAIMessage: true,
            },
          });
          const storageRef = ref(storage);
          const imagesRef = ref(storageRef, `${currentUser.id}/${file.name}`);
          await uploadBytes(imagesRef, file);
          const url = await getDownloadURL(imagesRef);
          if (message.data.role == "tool") {
            const token = (await auth.currentUser?.getIdToken(true)) ?? "";
            await sendMessageToAPI({
              token: token,
              route: "chat/upload-image",
              data: {
                id: currentUser.id,
                conversationId: message.conversation,
                messageId: message.id,
                tool_call_id: message.data.tool_call_id,
                url: url,
              },
            });
          }
        } catch (error: any) {
          addNotification({ title: "Error", body: error, type: "error" });
          console.log(error);
          setAvatarLoading(false);
          const token = (await auth.currentUser?.getIdToken(true)) ?? "";
          await sendMessageToAPI({
            token: token,
            route: "chat/create",
            data: {
              id: currentUser.id,
              conversationId: message.conversation,
              messageCount: messagesLength,
              lastInteraction: Timestamp.now(),
              awaitingAIMessage: false,
            },
          });
        }
      },
      [currentUser]
    );

    const handleMessageButton = (text: string) => {
      if (messagesLength == index) onSendMessage?.(text);
    };

    const handleTryAgainProcessAI = async () => {
      if (loadingTryAgainProcessAI) return
      setLoadingTryAgainProcessAI(true);
      try {
        const token = (await auth.currentUser?.getIdToken(true)) ?? "";
        await sendMessageToAPI({
          token: token,
          route: "chat/send-message/reprocess",
          data: {
            id: currentUser.id,
            conversationId: message.conversation,
            messageId: message.id,
          },
        });
        setLoadingTryAgainProcessAI(false);
      } catch {
        setLoadingTryAgainProcessAI(false);
      }
    }

    const convertDayToDayOfWeek = (day: string) => {
      const date = new Date(day)
      const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      return daysOfWeek[date.getDay()]
    }

    switch (action.type) {
      case "showProfile":
        if (!action.profile) return;
        return (
          <Profile
            profile={action.profile}
            onClick={() => goToProfile(action.profile.id)}
          />
        );
      case "showProfileCarousel":
        if (!action.profiles.length) return;
        return (
          <div className="">
            <div
              className={`snap-x flex flex-nowrap overflow-y-hidden overflow-x-scroll scrollbar-thin ${action.profiles.length == 1 ? "w-full" : "pr-[20px]"} space-x-4`}
            >
              {action.profiles.map((profile) => (
                <ContactSuggestion
                  key={profile.id}
                  onClick={() => goToProfile(profile.id)}
                  firstName={profile.firstName!}
                  lastName={profile.lastName!}
                  role={profile.professionalRoles?.[0]}
                  company={profile.industryFocus?.join(`, `)}
                  atributeList={
                    profile.professionalRoles &&
                      profile.professionalRoles.length > 1
                      ? profile.professionalRoles.slice(1)
                      : []
                  }
                  image={profile.avatarUrl}
                />
              ))}
            </div>
          </div>
        );
      case "showEvent":
        const event = Array.isArray(action.event)
          ? action.event[0]
          : action.event;
        if (!event || !event.id) return;
        return (
          <EventCard
            onClick={() => goToEvent(event.id)}
            title={event.name}
            description={event.tags ? event.tags.join(", ") : ""}
            image={event.bannerURL}
            location={event.location}
            subtitle={
              event.startDate
                ? `${convertDayToDayOfWeek(dayjs(event.startDate._seconds * 1000).format("YYYY-MM-DD"))} (${formatFirestoreTimestamp(
                  new Timestamp(
                    event.startDate._seconds,
                    event.startDate._nanoseconds
                  ),
                  `MM/DD/YY`
                )})`
                : ""
            }
          />
        );
      case "showConferenceAgenda":
        return (
          <div className="flex justify-start">
            <div
              className="bg-grey-1 border border-grey-1.1 shadow-default flex flex-row items-center px-4 py-3 space-x-2"
              onClick={goToConferenceAgenda}
            >
              <IconSchedule className="text-primary-3" />
              <p className="text-sm font-medium text-black-2">
                Conference Agenda
              </p>
            </div>
          </div>
        );
      case "showDeal":
        if (!action.deal) return;
        const date = action.deal.created.seconds;
        return (
          <DealCard
            dealId={action.deal.id}
            title={action.deal.title}
            date={
              date
                ? formatFirestoreTimestamp(action.deal.created, `DD/MM/YYYY`)
                : formatFirestoreTimestamp(Timestamp.now(), `DD/MM/YYYY`)
            }
            tags={action.deal.tags}
            onClick={() => goToDeal(action.deal.id)}
          />
        );
      case "showDealCarousel":
        if (!action.deals.length) return;
        return (
          <div>
            <div className="snap-x flex flex-nowrap overflow-y-hidden overflow-x-scroll scrollbar-thin pr-[20px] space-x-4">
              {action.deals.map((deal) => {
                const date = deal.created.seconds;
                return (
                  <DealCard
                    key={deal.id}
                    dealId={deal.id}
                    title={deal.title}
                    date={
                      date
                        ? formatFirestoreTimestamp(deal.created, `DD/MM/YYYY`)
                        : formatFirestoreTimestamp(
                          Timestamp.now(),
                          `DD/MM/YYYY`
                        )
                    }
                    tags={deal.tags}
                    onClick={() => goToDeal(deal.id)}
                  />
                );
              })}
            </div>
          </div>
        );
      case "showButtons":
        return (
          <div className="flex flex-col space-y-2">
            {action.buttons.map((button) => (
              <MessageButton
                key={button.text}
                text={button.text}
                theme={button.priority == 1 ? "default" : "light"}
                onClick={() => handleMessageButton(button.text)}
              />
            ))}
          </div>
        );
      case "showSuggestionsButtons":
        return (
          <div className="h-auto flex flex-col items-end space-y-2">
            {action.buttons.map((button) => (
              <SuggestionButton
                key={button.text}
                text={button.text}
                showIconAI={false}
                onClick={(text) =>
                  handleMessageButton(button.index || button.text)
                }
              />
            ))}
          </div>
        );
      case "showConversation":
        if (!action.conversationId) return;
        return (
          <MessageCard
            conversationId={action.conversationId}
            onProfile={(profileId) => goToProfile(profileId)}
            onClick={() => goToChat(action.conversationId)}
          />
        );
      case "showRequestDeal":
        if (!action.deal) return;
        return <RequestDeal deal={action.deal} />;
      case "showRequestedDeal":
        if (!action.deal) return;
        return (
          <RequestedDeal
            deal={action.deal}
            introduction={action.introduction}
          />
        );
      case "changeAvatar":
        if (action.uploaded) {
          return null;
        } else {
          return (
            // <ChooseAvatar
            //   disable={action.uploaded}
            //   loading={avatarLoading}
            //   onSelected={(file) => chooseAvatar(file, message)}
            //   onBlur={(blur) => (onBlur ? onBlur(blur) : null)}
            //   setFileExplorerOpen={(isOpen) =>
            //     setFileExplorerOpen ? setFileExplorerOpen(isOpen) : null
            //   }
            // />
            <ChooseImage
              disable={action.uploaded}
              loading={avatarLoading}
              onSelected={(file) => chooseAvatar(file, message)}
              onBlur={(blur) => (onBlur ? onBlur(blur) : null)}
              setFileExplorerOpen={(isOpen) =>
                setFileExplorerOpen ? setFileExplorerOpen(isOpen) : null
              }
            />
          );
        }
      case "showFirstOnboarding":
        return (
          <FirstCardOnboarding
            accepted={action.accepted}
            loading={loading ? loading : false}
            onClickMessage={(decision) => acceptProfile?.(decision, message)}
          />
        );
      case "showSecondOnboarding":
        return (
          <SecondCardOnboarding />
        );
      case "showThirdOnboarding":
        return <ThirdCardOnboarding onClickMessage={() => completeOnboarding?.(message)} />;
      case "offerBookMeeting":
        if (!action.meeting || !action.type) return;
        return <OfferMeeting type={action.type} meeting={action.meeting} />;
      case "confirmBookMeeting":
        if (!action.meeting || !action.type) return;
        return <ConfirmMeeting type={action.type} meeting={action.meeting} />;
      case "cancelBookMeeting":
        if (!action.meeting || !action.type) return;
        return <CancelMeeting type={action.type} meeting={action.meeting} />;
      case "showMeetingReminder":
        return (
          <div className="flex flex-col space-y-3">
            {action.meeting.map((meet, key) => {
              const participant = meet.participants.filter((f) => f !== "RAI");
              const day = `${getEventDay(meet.start)} ${formatFirestoreTimestamp(meet.start, `DD/MM/YY`)}, ${formatFirestoreTimestamp(meet.start)} - ${formatFirestoreTimestamp(meet.end)}`;
              return (
                <MessageCardGroup
                  title={meet.title}
                  subtitle={day}
                  key={meet.id + key}
                  theme="joinedMeeting"
                  participants={participant}
                  onClick={() => goToMeeting(meet.start, meet.id)}
                />
              );
            })}
          </div>
        );
      case "Error":
        return (
          <TryAgainAI onClick={handleTryAgainProcessAI} />
        );
      default:
        return <div></div>;
    }
  }
);

ActionComponent.displayName = "ActionComponent";

const DynamicComponent = ({
  message,
  index,
  messagesLength,
  toUser,
  loading,
  premiumMember,
  onboardedViaAI,
  acceptProfile,
  completeOnboarding,
  setFileExplorerOpen,
  onBlur,
  onSendMessage,
}: DynamicComponentProps) => {
  const { currentUser } = useAuth();

  const convertAssistantMessage = (content: string | object) => {
    try {
      if (typeof content === "string") {
        return JSON.parse(content);
      }
      if (typeof content === "object") return content;
    } catch (error) {
      return content;
    }
  };

  switch (message.data.role) {
    case "user":
      if (Array.isArray(message.data.content)) {
        return message.data.content.map((m, key) => (
          <Message key={`${message.id}-${key}`} text={"Array content"} />
        ));
      } else {
        return (
          <Message
            key={message.id}
            image={toUser?.avatarUrl}
            firstName={toUser && !toUser.avatarUrl ? toUser?.firstName : ""}
            premiumMember={premiumMember}
            theme={
              message.from == currentUser.id ? "UserMessage" : "AnswerMessage"
            }
            position={message.from == currentUser.id ? "right" : "left"}
            text={message.data.content?.toString() || ``}
            hour={
              message.from == currentUser.id
                ? ""
                : formatFirestoreTimestamp(message.timestamp)
            }
          />
        );
      }
    case "assistant":
      if (message.data.content) {
        const convertedMessage = convertAssistantMessage(message.data.content);
        if (typeof convertedMessage === "object")
          return (
            <ActionComponent
              key={message.id}
              message={message}
              action={convertedMessage}
              onboardedViaAI={onboardedViaAI}
              acceptProfile={acceptProfile}
              completeOnboarding={completeOnboarding}
              setFileExplorerOpen={setFileExplorerOpen}
              onBlur={onBlur}
              loading={loading}
              index={index}
              messagesLength={messagesLength}
              onSendMessage={(text) => onSendMessage?.(text)}
            />
          );
        return (
          <div className="" key={message.id}>
            <MessageAssistant
              key={message.id}
              message={
                <span key={message.id}>
                  {message.data.content?.split("\n\n").map((str, key) => (
                    <span key={`${message.id}-${key}`}>
                      <Markdown key={`${message.id}-${key}`}>{str}</Markdown>
                      <br />
                    </span>
                  ))}
                </span>
              }
            />
          </div>
        );
      } else {
        if (!message.data.tool_calls?.length) return null;
        const actionName = message.data.tool_calls[0].function.name || "";
        const messageFixed = actionAI[actionName]
          ? actionAI[actionName].label || ""
          : "";
        if (!messageFixed) return null;
        return (
          <div className="" key={message.id}>
            <MessageAssistant
              key={message.id}
              message={
                <span key={message.id}>
                  {messageFixed.split("\n\n").map((str, key) => (
                    <span key={`${message.id}-${key}`}>
                      <Markdown key={`${message.id}-${key}`}>{str}</Markdown>
                      <br />
                    </span>
                  ))}
                </span>
              }
            />
          </div>
        );
      }
    case "tool":
      return (
        <ActionComponent
          key={message.id}
          message={message}
          action={JSON.parse(message.data.content)}
          onboardedViaAI={onboardedViaAI}
          acceptProfile={acceptProfile}
          completeOnboarding={completeOnboarding}
          setFileExplorerOpen={setFileExplorerOpen}
          onBlur={onBlur}
          index={index}
          loading={loading}
          messagesLength={messagesLength}
        />
      );
    case "system":
      return (
        <ActionComponent
          key={message.id}
          message={message}
          action={JSON.parse(message.data.content)}
          onboardedViaAI={onboardedViaAI}
          acceptProfile={acceptProfile}
          completeOnboarding={completeOnboarding}
          setFileExplorerOpen={setFileExplorerOpen}
          onBlur={onBlur}
          index={index}
          loading={loading}
          messagesLength={messagesLength}
        />
      );
    case "function":
      return null;
    case "suggestions":
      return (
        <div ref={message.data.reference}>
          <SuggestionList
            suggestions={message.data.content}
            referenceScroll={message.data.referenceScroll}
            onClick={(text) => onSendMessage?.(text)}
          />
        </div>
      );
    default:
      return null;
  }
};
DynamicComponent.displayName = "DynamicComponent";
export default React.memo(DynamicComponent);
